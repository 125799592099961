<template>
  <div
    class="landing-container"
    style="justify-content: center; align-items: center; padding: 0px;"
  >
    <v-icon
      class="text-h1 mb-4"
      color="var(--eastman-azure)"  
    >
      mdi-check-circle-outline
    </v-icon>
    <span
      class="text-h2 azure-text"
    >
      {{ title }}
    </span>
    <!-- <span
      class="text-body-1 mt-8"
      v-if="source === 'contact'"
    >
     {{ $t('ContactCompleteNote') }}
    </span> -->
    <span
      class="text-h5 mt-6 mb-12"
    >
      {{ subtitle }}
    </span>
    <div
      class="do-next-container"
      v-if="source === 'contact' || source === 'feedback'"
    >
      <v-row justify="center">
        <v-col cols="auto">
          <span class="text-h5">
            What do you want to do next?
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
        cols="auto"
        v-if="this.currentFormulation && this.currentFormulation.recs && this.currentFormulation.recs.length > 0"
        >
          <v-btn
            outlined
            small
            color="var(--eastman-azure)"
            to="/coatings/results"
          >
            {{ $t('CompleteBackBtn') }}
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            outlined
            small
            color="var(--eastman-azure)"
            to="/"
          >
            {{ $t('CompleteNewFormulationBtn') }}
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            outlined
            small
            color="var(--eastman-azure)"
            @click="openPharmaUrl"
          >
            {{ $t('CompleteProductsBtn') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "Complete",
  props: {
    source: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapState(['currentFormulation']),
    title: function () {
      if (this.source === 'register') { return this.$t('RegisterCompleteTitle') }
      if (this.source === 'contact') { return this.$t('ContactCompleteTitle') }
      if (this.source === 'feedback') { return this.$t('FeedbackCompleteTitle') }
      return ''
    },
    subtitle: function () {
      if (this.source === 'register') { return this.$t('RegisterCompleteSubtitle') }
      if (this.source === 'contact') { return this.$t('ContactCompleteNote') }
      // if (this.source === 'feedback') { return this.$t('FeedbackCompleteSubtitle') }
      return ''
    },
  },
  methods: {
    openPharmaUrl: function () {
      window.open(process.env.VUE_APP_PHARMA_URL)
    },
  },
}
</script>

<style>
.do-next-container {
  border: solid 1px #00000030;
  background-color: #ffffff30;
  padding: 2rem 3rem;
}
</style>